import { HttpClient } from '@angular/common/http';
import { Observable, forkJoin } from 'rxjs';
import { environment } from 'src/environments/environment';
import { IEnterprise } from '../model/enterprise.interface';
import { Injectable } from '@angular/core';
import ICompany, { WhyWorkWithUs, HomePage, IntroText, IntroTextPayload, WhyWorkWithUsSection } from '../model/company.interface';
import { map, tap } from 'rxjs/operators';
import { Role, Roles } from '../model/role.interface';
import { TranslationKeys } from '../model/translation-object.interface';
import { UserService } from './user.service';
import { Enterprise } from '../classes/organization.class';
import { ILibraryData } from '../model/library-data.interface';
import { changeVideoExtension } from '../resources/video-url-transformation-functions';

@Injectable({
  providedIn: 'root',
})
export class EnterpriseService {

  private _roles: Role[] = [
    { value: Roles.enterpriseRM, name: {
      en:'Global Regional Manager',
      sv: 'Global Regional Manager'
    }
   },
    { value: Roles.enterpriseHR, name: {
      en:'Global HR',
      sv: 'Global HR'
    }
  },
  ];
  private _currentEnterprise: Enterprise;
  private _enterpriseId: number;

  constructor(
    private http: HttpClient,
    private userService: UserService
  ) {}

  get enterpriseId(): number {
    return this._enterpriseId;
  }

  get currentEnterprise(): Enterprise {
    return this._currentEnterprise;
  }

  set enterpriseId(id: number) {
    this._enterpriseId = id;
  }

  get roles(): Role[] {
    return this._roles;
  }

  get enterprisePossibleLanguages(): TranslationKeys[] {
    const languages: TranslationKeys[] = [TranslationKeys.EN];

    this.currentEnterprise.companies
      .forEach(({language}: ICompany) => {
        if (!languages.includes(language)) {
          languages.push(language);
        }
      });

    return languages;
  }

  getEnterpriseInfo(): Observable<Enterprise> {
    return this.http.get(`${environment.enterprises}/${this.enterpriseId}`).pipe(
      map((enterpriseValue: IEnterprise) => {
        const enterprise = new Enterprise(enterpriseValue, this.userService.user);
        this._currentEnterprise = enterprise;
        return enterprise;
      })
    );
  }

  updateEnterprisePageStatuses(
    homePageStatus: boolean,
    whyWorkWithUsStatus: boolean,
    introText: boolean
  ): Observable<IEnterprise> {
    return this.http.put<IEnterprise>(
      `${environment.enterprises}/${this.enterpriseId}`,
      {
        useEnterpriseLandingPage: homePageStatus,
        useEnterpriseWhyWorkWithUs: whyWorkWithUsStatus,
        useEnterpriseIntro: introText,
      }
    ).pipe(
      tap((enterprise: IEnterprise) => {
        this._currentEnterprise = new Enterprise(enterprise, this.userService.user);
      })
    );
  }

  getEnterpriseWhyWorkWithUs(pageId: number): Observable<WhyWorkWithUsSection[]> {
    return this.http
      .get<WhyWorkWithUs>(`${environment.enterpriseWhyWorkWithUs}/${pageId}`)
      .pipe(
        map((wwwu: WhyWorkWithUs) => {
          const {sections, id, ...firstSection} = wwwu;

          return [firstSection].concat(sections || []);
        })
      );
  }

  updateEnterpriseWhyWorkWithUs(payload: any): Observable<WhyWorkWithUs> {
    return this.http.post<WhyWorkWithUs>(
      environment.enterpriseWhyWorkWithUs,
      payload
    );
  }

  getEnterpriseIntroText(id: number): Observable<IntroText> {
    return this.http.get<IntroText>(`${environment.enterpriseIntroText}/${id}`);
  }

  updateEnterpriseIntroText(payload: IntroTextPayload): Observable<IntroText> {
    return this.http.post<IntroText>(`${environment.enterpriseIntroText}`, payload);
  }

  getEnterpriseCareerHomePage(enterpriseId: number): Observable<HomePage> {
    return this.http.get<HomePage>(`${environment.enterpriseHomePage}/${enterpriseId}`);
  }

  updateEnterpriseCareerHomePage(payload: any): Observable<HomePage> {
    return this.http.post<HomePage>(environment.enterpriseHomePage, payload);
  }

  getEnterpriseVideoLibrary(): Observable<ILibraryData[]> {
    const requests$ = [];
    this._currentEnterprise.companies.forEach(company => {
      requests$.push(
        this.http
          .get<ILibraryData[]>(`${environment.companies}/${company.guid}/job_videos`)
          .pipe(
            map((data) => {
              data.map(item => item.videoPoster = changeVideoExtension(item.video, 'jpg'));
              return data;
            })
          )
      );
    });
    return forkJoin(requests$)
      .pipe(
        map(data => {
          const result = [];
          data.forEach(companyLibraryData => result.push(...companyLibraryData));
          return result;
        })
      );
  }

  getEnterpriseImageLibrary(): Observable<ILibraryData[]> {
    const requests$ = [];
    this._currentEnterprise.companies.forEach(company => {
      requests$.push(
        this.http
          .get<any>(`${environment.companies}/${company.guid}/job_images`)
      );
    });
    return forkJoin(requests$)
      .pipe(
        map(data => {
          const result = [];
          data.forEach(companyLibraryData => result.push(...companyLibraryData));
          return result;
        })
      );
  }
}
